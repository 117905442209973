<template>
  <div class="mb-2 w-100">
    <div class="rounded p-2 d-flex flex-column shadow-sm" v-background3>
      <div class="d-flex flex-row align-items-center sticky-top" v-background3>
        <slot name="title"></slot>
        <div class="d-flex align-items-center">
          <button-circle v-if="displayForm" name="help-circle" size="18" @click="$emit('help')"></button-circle>
          <button-circle
            v-if="displayForm"
            name="trash-2"
            size="18"
            @click="destroy"
            :loading="loading"
          ></button-circle>
          <BaseButton
            v-if="displayForm"
            class="ml-2 mr-2"
            @click="$emit('save')"
            text="Enregistrer"
            icon="save"
            :loading="loading"
          ></BaseButton>
          <button-circle
            v-if="displayForm"
            name="arrow-up"
            size="18"
            class="mt-2 mb-2"
            @click="toogle"
          ></button-circle>
          <button-circle
            v-if="!displayForm"
            name="arrow-down"
            size="18"
            class="mt-2 mb-2"
            @click="toogle"
          ></button-circle>
        </div>
      </div>
      <transition
        enter-active-class="enter-active"
        leave-active-class="leave-active"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
      >
        <div v-if="displayForm" class="mt-1 pl-3 pr-3">
          <slot name="form"></slot>
        </div>
      </transition>
    </div>
    <transition
      enter-active-class="enter-active"
      leave-active-class="leave-active"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @before-leave="beforeLeave"
      @leave="leave"
      @after-leave="afterLeave"
    >
      <div v-if="displayForm">
        <slot name="actions"></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import ButtonCircle from "@/components/bases/ButtonCircle.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  name: "ContainerListExtend",
  components: { ButtonCircle, BaseButton },
  data() {
    return {
      displayForm: false
    };
  },
  props: {
    text: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    toogle: function() {
      this.displayForm = !this.displayForm;
      this.$emit("toggle");
    },

    destroy: function() {
      if (window.confirm("Confirmer la suppression définitive"))
        this.$emit("destroy");
    },

    /**
     * @param {HTMLElement} element
     */
    beforeEnter(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = "0px";
        }

        element.style.display = null;
      });
    },
    enter(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`;
        });
      });
    },
    afterEnter(element) {
      element.style.height = null;
    },
    beforeLeave(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`;
        }
      });
    },
    leave(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = "0px";
        });
      });
    },
    afterLeave(element) {
      element.style.height = null;
    }
  },
  computed: {}
};
</script>
<style>
.enter-active,
.leave-active {
  overflow: hidden;
  -moz-transition: height 0.2s linear;
  -ms-transition: height 0.2s linear;
  -o-transition: height 0.2s linear;
  -webkit-transition: height 0.2s linear;
  transition: height 0.2s linear;
}
</style>
