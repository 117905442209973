<template>
  <container-form-extend
    class="d-flex flex-column w-100"
    @save="updateSuivi"
    @destroy="deleteSuivi"
    :loading="loading"
  >
    <template v-slot:title>
      <div class="p-1 p-md-2 rounded-top d-flex flex-row align-items-center flex-grow-1">
        <h6 class="m-0 ml-1" v-if="suivi.visite_date">
          Le
          {{ $moment(suivi.visite_date).format("D MMM YYYY") }}
        </h6>
        <h6 class="m-0 ml-1" v-else>Nouvelle visite</h6>
      </div>
    </template>
    <template v-slot:form>
      <div>
        <base-form-row row>
          <base-inputDatePicker
            v-model="suivi.visite_date"
            inputText="Date de visite"
            :errors="feedback.visite_date"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="suivi.visite_synthese"
            text="Synthèse"
            :errors="feedback.visite_synthese"
          ></base-input-area>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Site client"
            v-model.number="suivi.visite_site_id"
            :options="data.sites"
            :errors="feedback.visite_site_id"
          ></base-select>
        </base-form-row>
        <div class="w-100">
          <uploader
            v-if="suivi.id"
            id="document_affaire_suivi"
            url="/upload/document"
            title="Pièces jointes"
            :file_system="'CRM'"
            :file_field="'suivi_' + suivi.id"
            :file_key="id"
          ></uploader>
        </div>
      </div>
    </template>
  </container-form-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { errHandler } from "@/helpers.js";
import ContainerFormExtend from "@/components/containers/ContainerFormExtend.vue";
//import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseSelect from "@/components/bases/Select.vue";
import Uploader from "@/components/bases/Uploader.vue";

export default {
  name: "FormationsItem",
  components: {
    ContainerFormExtend,
    //BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseSelect,
    Uploader
  },
  data() {
    return {
      feedback: {},
      loading: false
    };
  },
  props: {
    suivi: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    lists: {
      Type: Object,
      Requierd: true,
      Default: {}
    }
  },
  computed: {
    ...mapGetters({ data: "affaires/data" }),
    ...mapFields("affaires", ["affaire.id"])
  },
  methods: {
    ...mapActions({
      _updateSuivi: "affaires/updateSuivi",
      _deleteSuivi: "affaires/deleteSuivi"
    }),
    updateSuivi: function() {
      this.feedback = {};
      this.loading = true;
      this._updateSuivi(this.suivi)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteSuivi: function() {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null
      this.feedback = {};
      this.loading = true;
      this._deleteSuivi(this.suivi)
        .catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style lang="css"></style>
